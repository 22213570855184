.header {
  display: flex;
  justify-content: space-between;
  align-content: center; }
  .header h1 {
    margin: 0;
    padding: 0;
    line-height: 1em; }
  .header .icon {
    font-size: 2em;
    color: #f55649;
    line-height: 1em; }

@media screen and (max-width: 768px) {
  .header h1 {
    order: 1; }
  .header .icon {
    order: 2; } }
