.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .bg:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: background-color 0.1s ease-in; }
  .bg.loaded:after {
    background-color: rgba(255, 255, 255, 0.6); }
