footer {
  padding: 30px 0;
  position: relative;
  text-align: center;
  font-size: 14px;
  line-height: 14px; }
  footer span:after, footer a:after {
    content: '';
    padding: 1em; }
  footer span:last-child:after, footer a:last-child:after {
    padding: 0; }
