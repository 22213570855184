body, div, p {
  position: relative; }

body {
  padding: 0;
  margin: 0;
  font-family: "Segoe UI", "Roboto", "San Francisco", "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #4c4c4c; }

h1, h2, h3, h4, h5 {
  font-family: "Josefin Sans", "Segoe UI", "Roboto", "San Francisco", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  color: #f55649; }

a, a:visited, .clickable {
  cursor: pointer;
  text-decoration: none;
  color: inherit; }

code {
  padding: 1px;
  background: #e8e8e8;
  color: #f55649; }

#bl-root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.wrap {
  max-width: 1080px;
  padding: 0 14px;
  margin: 0 auto;
  border-bottom: 1px solid #cecece; }

.header {
  border-bottom: 1px solid #cecece;
  padding: 30px 0; }

.body {
  display: flex;
  padding: 14px 0;
  flex-direction: row; }
  .body .nav-main {
    order: 2;
    flex: 0 0 250px; }
  .body .main {
    order: 1;
    flex: 1 1 auto;
    overflow-x: hidden; }

@media screen and (max-width: 768px) {
  .body {
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: column; }
    .body .nav-main {
      order: 1;
      flex: 1 1 auto; }
    .body .main {
      order: 2;
      flex: 1 1 auto; } }
