.nav-main {
  font-family: "Josefin Sans", "Segoe UI", "Roboto", "San Francisco", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  color: #f55649;
  text-align: right; }
  .nav-main ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .nav-main ul li {
      padding: 15px 10px; }
      .nav-main ul li.active {
        font-weight: bolder; }

@media screen and (max-width: 768px) {
  .nav-main {
    background: #f55649;
    padding: 5px;
    color: #fff;
    text-align: left; }
    .nav-main ul li {
      display: inline;
      padding: 0 25px 0 0; } }
